import { Box } from "@chakra-ui/layout";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import React from "react";
import { generateLocationRoute } from "../../../../lib/routes";
import { Button } from "../../Button/Button";
import { LocationCard as ILocationCard } from "../model/location-card.model";

export interface LocationCardProps extends ILocationCard {
  className?: string;
}

export const LocationCard = (props: LocationCardProps) => {
  const { className = "", slug, image, name, shortText } = props;
  const firstImage = getImage(image);
  const bgImage = convertToBgImage(firstImage);

  return (
    <Link to={generateLocationRoute(slug)}>
      <Box
        shadow="dark-lg"
        _hover={{
          shadow: "2xl",
        }}
        as="article"
        className={`${className} group cursor-pointer transition-all duration-300`}
      >
        <BackgroundImage preserveStackingContext {...bgImage} className="">
          <Box
            height={{
              base: 200,
              lg: 280,
            }}
            className="relative"
          >
            <div className="overlay h-full w-full bg-gray-900 opacity-60 absolute z-0 group-hover:opacity-50 transition-all duration-300"></div>
            <div className="content z-10 relative flex flex-col items-center justify-center h-full">
              <h2 className="text-primary text-4xl lg:text-5xl font-medium uppercase">
                {name}
              </h2>
              <h5 className="text-white font-medium text-lg lg:text-2xl mt-2 text-center">
                {shortText}
              </h5>
              <Link
                className="mt-4 lg:opacity-0 group-hover:opacity-100 transition-all duration-500 "
                to={generateLocationRoute(slug)}
              >
                <Button>OTVORIŤ LOKALITU</Button>
              </Link>
            </div>
          </Box>
        </BackgroundImage>
      </Box>
    </Link>
  );
};
