import React from "react";
import { Container } from "../../components/Shared/Container/Container";
import Layout from "../../components/Shared/Layout/Layout";
import { LocationCards } from "../../components/Shared/LocationCards/LocationCards";
import { MessageFormSection } from "../../components/Shared/MessageFormSection/MessageFormSection";

export interface LocationsPageProps {
  className?: string;
}

export const LocationsPage = (props: LocationsPageProps) => {
  const { className = "" } = props;
  return (
    <Layout className={`${className}`}>
      <section className="py-16">
        <LocationCards />
      </section>
      <div className="form mt-16">
        <MessageFormSection /> 
      </div>
    </Layout>
  );
};

export default LocationsPage;
