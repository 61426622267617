import { graphql, useStaticQuery } from "gatsby";
import { LocationCard } from "../model/location-card.model";

export const useLocations = (): LocationCard[] => {
  const {
    allStrapiLocation: { nodes },
  } = useStaticQuery(graphql`
    {
      allStrapiLocation(sort: { fields: published_at, order: DESC }) {
        nodes {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          name
          slug
          shortText
        }
      }
    }
  `);

  return nodes;
};
