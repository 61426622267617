import { Box } from "@chakra-ui/layout";
import { Link } from "gatsby";
import React, { useMemo } from "react";
import { routes } from "../../../lib/routes";
import { Button } from "../Button/Button";
import { Container } from "../Container/Container";
import { List } from "../List/List";
import { SectionHeader } from "../SectionHeader/SectionHeader";
import { LocationCard } from "./components/LocationCard";
import { useLocations } from "./hooks/useLocations";

export interface LocationCardsProps {
  className?: string;
  limit?: number;
}

export const LocationCards = (props: LocationCardsProps) => {
  const { className = "", limit } = props;
  const locations = useLocations();

  const locationItems = useMemo(() => {
    if (limit) {
      return locations.slice(0, limit);
    }
    return locations;
  }, [locations, limit]);

  return (
    <section className={`${className}`}>
      <Container className="grid-cols-1 lg:grid-cols-2 grid gap-8">
        <Box
          as="article"
          shadow="dark-lg"
          className="row-span-2 bg-secondary p-4 lg:p-10 text-white flex flex-col justify-between shadow-xl"
        >
          <SectionHeader
            variant="light"
            subtitle="Realitné lokality"
            title="VYBERTE SI LOKALITU"
            description="Zhrnul som pre Vás množstvo zaujímavých
            informácií, ktoré Vám pomôžu pri výbere lokality"
          />
          <p className="mt-8 lg:mt-2">
            Výber lokality je kľúčový pre spokojné bývanie. Odporúčam Vám zvážiť
            aj vplyv lokality na budúcu hodnotu nehnuteľnosti, či v prípade
            potreby možnosť nehnuteľnosť výhodne prenajímať
          </p>
          <List
            type="check"
            className="mt-4"
            items={[
              "Venujte dostatok času výberu tej správnej lokality",
              "Zvážte vplyv ekonomiky na cenu nehnuteľností v lokalite",
            ]}
          />
          <p className="mt-4">V prípade potreby ma neváhajte kontaktovať</p>
          <Link className="mt-4 flex" to={routes.contact.to}>
            <Button
              flexGrow={{
                base: 1,
                lg: 0,
              }}
              className=" flex"
            >
              MÁM ZÁUJEM O KONZULTÁCIU
            </Button>
          </Link>
        </Box>
        {locationItems.map((location) => (
          <LocationCard key={location.slug} {...location} />
        ))}
      </Container>
    </section>
  );
};
